/** @format */
@import '../../../assets/scss/colorCode.scss';
.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10% 0;
    row-gap: 20px;
    .inputs-container{
        width: 280px;
        .login-title {
            font-size: 2vw;
            font-family: appMedium;
            color: #585858;
            margin-bottom: 20px;
        }
        .code-and-no-container{
            display: flex;
            justify-content: space-between;
            margin: 20px 0 0 0;
            .code-container{
                width: 20%;
            }
            .no-container{
                width: 70%;
            }
            
        }
        .edit-container {
            display: flex;
            justify-content: center;
            cursor: pointer;
            align-items: center;
            margin-top: 5px;
        }
        
    }

    .mui-forminput {
        width: 114px;
    }

    .phoneNumberInput {
        font-family: 'appMedium';
        width: 280px;
        height: 37.25px;
    }

    .phoneInputDisabled {
        color: #b1b1b1;
        // background-color: rgba(0, 0, 0, 0.12);
        background-color: #f5f5f5;
        //
        // background-color: #faf9f9;
    }

    .react-tel-input {
        .flag-dropdown{
            background-color: white;
        }
        // .selected-flag{
        //     background-color: #f5f5f5;
        // }
        .selected-flag:focus{
            // background-color: rgba(0, 0, 0, 0.12)
        }
        .form-control {
            font-size: 12px;
        }
        .country-list {
            width: 280px;
        }
    }

    // .edit-container {
    //     display: flex;
    //     justify-content: right;
    //     cursor: pointer;
    //     align-items: center;
    //     margin-top: 5px;
    // }

    .otp-disabled {
        cursor: not-allowed;
        background-color: #f5f5f5;
    }

    .send-otp-button {
        width: 55%;
        color: #ffffff;
        // background-color: #585858;
        background-color: $bayer-bright-green;
        text-transform: none;
        font-family: appMedium;
        white-space: nowrap;
        // height: 28px;
        height: 7vh;
        border-radius: 30px;
        font-size: 13px;
        margin-right: 1vw;
        // margin-top: 60px;
    }
    .send-otp-button:hover {
        background-color: $bayer-green
    }

    .resend_text {
        letter-spacing: 0px;
        color: #00bcff;
        opacity: 1;
        font-size: 10px;
        padding-left: 6px;
        text-align: right;
        font-family: 'appMediumItalic';
        text-decoration: underline;
        // margin-right: 1vw;

        // padding-top: 5px;
        // padding-right: 5px;
        margin-top: 5px;
        cursor: pointer;
    }

    .resend_text_timer {
        font-size: 10px;
        // margin-right: 1vw;
        text-align: right;
        opacity: 1;
        color: #969696;
        font-family: 'appMedium';

        @media (max-width: 400px) {
            font-size: 10px;
        }
    }

    .success {
        background-color: #89d329;
        color: #ffffff;
        font-size: 18px;
        max-width: 370px;
    }

    .send-otp-text {
        font-family: appMedium;
        font-size: 10px;
    }

    .submitbtn__wrapper {
        display: flex;
        justify-content: center;
        // margin-top: -8px;
        width: 100%;
        margin-top: 0 !important;
        // margin-bottom: 4vh;
    }

    .submit__register {
        background-image: linear-gradient(
            108deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 73%,
            #66b512 50%
        );

        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin-top: 0 !important;
        width: 90% !important;
    }

    .submit__register:hover {
        cursor: pointer;
        background-color: #66b512;
    }

    .login-btn {
        text-transform: uppercase;
        color: #fff;
        cursor: pointer;
        background: url('../../../assets/img/svg/reusable/rtn_btn.svg')
            no-repeat right;
        background-color: #66b512;
        width: 320px;
        &:hover {
            background-color: #66b512;
            p {
                padding-left: 60px;
            }
        }
        border-radius: 30px;
        position: relative;
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        p {
            margin: 0;
            text-transform: none;
            font-size: 0.9rem;
            font-family: 'appMediumItalic' !important;
        }
        .arrow-i {
            position: relative;
            left: 116px;
            top: 0;
            width: 10px;
        }
        &:after {
            // content: "»";
            position: absolute;
            opacity: 0;
            top: 14px;
            right: -100px;
            transition: 0.3s;
            transition-timing-function: ease-out;
        }

        &:hover {
            .arrow-i {
                left: 40px;
                transition: left 0.3s;
                transition-timing-function: ease-out;
            }
        }

        &:hover:after {
            opacity: 1;
            right: 10px;
        }
        @media (max-width: 400px) {
            width: 200px;
            &:hover {
                p {
                    padding-left: 0px;
                }
            }
        }
    }
    .email-suggestion{
        width: 280px;
        color: #d32f2f;
        font-family: appRegular;
        @media(min-width: 1300px) {
            font-size: 14px;
        }
        font-size: 9px;
        
    }
}

.btn__regis__icon {
    width: 8%;
    justify-content: end;
    display: flex;
}
.btn__regis {
    display: flex;
    width: 100%;
}
.btn__regis__text__exlogin {
    width: 92%;
    font-size: 14px;
}


.ext-login-btm-div-2{
    display: flex;
    flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}

.css-k4qjio-MuiFormHelperText-root.Mui-error {
    margin-left: 0
}
