/** @format */
@import '../../../assets/scss/colorCode.scss';

.internal-login-container {
    margin: 0 0 10% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
        margin-bottom: 20px;
        .login-title {
            // font-size: 18px;
            font-size: 2vw;
            font-family: appMedium;
            color: #585858;
            margin-bottom: 0px;
        }
        .mui-forminput {
            width: 280px;
            margin-left: 3px;
        }
        .submitbtn__wrapper {
            display: flex;
            justify-content: center;
            margin-top: 60px;
            width: 100%;
        }
        .submit__register {
            background-image: linear-gradient(
                108deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 83%,
                #66b512 50%
            );
            width: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .submit__register:hover {
            cursor: pointer;
            background-color: #66b512;
        }
    }
    .btn__regis__icon {
        width: 8%;
        justify-content: end;
        display: flex;
    }
    .btn__regis {
        display: flex;
        width: 100%;
        align-items: center;
    }
    .btn__regis__text {
        width: 92%;
        font-family: appMedium;
    }

    .or-hr-container {
        color: $bayer-dark-grey;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
        .or-container {
            font-family: appMedium;
        }
        .background-filled-div {
            background-color: $bayer-medium-grey;
            height: 2px;
            width: 30%;
            margin: 0 5px;
        }
    }
}
