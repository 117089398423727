@import '../../../assets/scss/colorCode.scss';

.left-arrow{
    color: $bayer-grey;
    height: 13px;
    cursor: pointer;
}

.page-title{
    font-family: appBold;
    // font-size: 20px;
    font-size: 1.5vw;
    margin-left: 8px;
    color: $bayer-black;
}
.components-container{
    .error-container{
        color: $bayer-red;
        font-family: appRegular;
        font-size: 13px;
    }
    .cc-req-buttons-container{
        display: flex;
        justify-content: center;
        gap: 1vw;
        margin: 1vh 0 0 0 ;
        .arrow{
            position: absolute;
            right: 0.5vw;
            }
    }

    .submitbtn__wrapper {
        display: flex;
        justify-content: center;
        margin-top: 0px;
        width: 20vw
    }

    .submit__register {
        background-image: linear-gradient(
            108deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 83%,
            #66b512 50%
        );

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .submit__register:hover {
        cursor: pointer;
        background-color: #66b512;
    }
    .cc-request-filled-contents{
        font-family: appRegular;
    }
}

    .btn__regis__icon {
        width: 8%;
        justify-content: end;
        display: flex;
    }
    .btn__regis {
        display: flex;
        width: 100%;
    }
    .btn__regis__text {
        width: 92%;
        display: flex;
        justify-content: center;
        font-size: 17px;
    }