/** @format */

@font-face {
    font-family: 'appRegular';
    src: url('../fonts/HelveticaNeue-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'appBold';
    src: url('../fonts/HelveticaNeue-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'appMedium';
    src: url('../fonts/HelveticaNeue-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'appMediumItalic';
    src: url('../fonts/HelveticaNeue-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'appBoldItalic';
    src: url('../fonts/HelveticaNeue-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'appItalic';
    src: url('../fonts/HelveticaNeue-Italic.ttf') format('truetype');
}
