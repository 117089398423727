/** @format */

$bayer-grey: #585858;
$bayer-grey-one: #707070;
$bayer-medium-grey: #dce8eb;
$bayer-grey-background-tnc: #fafafa;

$bayer-blue: #10384f;
$bayer-light-blue: #0091df;
$bayer-dark-blue: #10384f;
$bayer-sky-blue: #0091df26;

$bayer-green: #66b512;
$bayer-dark-grey: #96a8ae;
$bayer-light-black: #323232;
$bayer-black: #000000;
$bayer-light-green-two: #94d251;

$bayer-light-green: #d9ecc4;
$bayer-bright-green: #89d329;
$bayer-bright-green-20opac: #89d32920;
$bayer-mild-green: #2b6636;
$bayer-dark-green: #004422;

$bayer-underline-header: #03bcfb;
$bayer-underline-header-20opac: #03bcfb20;

$bayer-light-red: #d0021b26;
$bayer-red: #d0021b;
