.confirm-popup-container{
    .confirm-modal-popup{
        background-color: white;
        border-radius: 20px;
        // height: 30vh;
        padding: 2vw;
        display: flex;
        flex-direction: column;
        row-gap: 2vw;
        .confirm-content{
            font-family: appBold;
            font-size: 16px;
            display: flex;
            justify-content: center;
            @media(max-width: 1200px) {
                font-size: 13px;
            }
        }
        .confirmmodal-buttons-container{
            display: flex;
            column-gap: 15px;
            justify-content: center;
            .arrow{
                position: absolute;
                right: 0.5vw;
            }
            .apply {
                display: flex;
                justify-content: center;
                .submit__register {
                    background-image: linear-gradient(
                        108deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 0) 83%,
                        #66b512 50%
                    );
        
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .btn__regis__icon {
                        width: 8%;
                        justify-content: end;
                        display: flex;
                    }
                    .btn__regis {
                        display: flex;
                        width: 100%;
                        align-items: center;
                    }
                    .btn__regis__text {
                        width: 92%;
                        font-family: appMedium;
                        font-size: 12px;
                    }
                }
                .submit__register:hover {
                    cursor: pointer;
                    background-color: #66b512;
                }
            }
        }
    }
}