.box__container{
    padding: 0vh 4vw 0vh 8vw;
}

.head__tab{
    display: flex;
    .tab__heading{
        width: 60%;
        .tab__name{
            font-size: 1.3vw;
            text-transform: capitalize;
            font-family: "appBold";
            color: #000000;

        }
        
    }
    .hz__filter{
        display: flex;
        width: 40%;
        span{
            margin-right: 40px;
            // margin-left: 20px;
        font-family: "appRegular";
        h2{
            font-size: 1.3vw;
        }
        }
    }
    .icon__container{
        display: flex;
        align-items: center;

        .cert_req_icon{
            height:1.6vw;
            width: 1.6vw;
            border-radius: 20px;
            color: #fff;
            font-size: 1vw;
            display: table-cell;
            text-align: center;
            line-height: 1.6vw;
            margin-left: 5px;
            margin-right: 2vw;

        }
        .pending__icon{
            background-color: #00BCFF;
            
        }
        .issued__icon{
            background-color: #66B512;
            
        }
        .declined__icon{
            background-color: #D0021B;
            
        }
        .expired__icon{
            background-color: #96A8AE;
            
        }
        h2{
            font-size: 1.2vw;
            
        }
       
}
}