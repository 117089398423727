.approval-popup{
    
    .MuiDialogTitle-root{
        padding: 0vw 1vw 1vw !important;
    }

    .MuiFormLabel-root{
        font-family: 'appRegular';
        font-size: 1vw;
    }

    .MuiSelect-select{
        font-family: 'appRegular';
        font-size: 1vw;
    }

    .MuiInputBase-root{
        font-family: 'appRegular';
        font-size: 1vw;
    }

    .MuiDialogActions-root{
        font-family: 'appRegular';
    }

    .MuiMenuItem-root{
        color: #323232 !important;
        font-family: 'appRegular';
        font-size: 1vw;
    }
}

.approval-popup-title{
    
    padding: 0vw 2vw !important;
    
    .MuiTypography-root {
        font-family: 'appBold' !important;
        font-size: 1.2vw !important;
        color: #323232;
        text-align: center;
    }
}

.popup-content{
    padding: 1vw 2vw 5vw !important;
}

.starlabel label:after {
    content:" *";
    color: red;
  }