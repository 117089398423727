@import '../../../../assets/scss/colorCode.scss';

.info-container{
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5vw;
    color: $bayer-grey;
    .info-icon{
        width: 14px;
    }
    .disclaimer{
        font-family: appRegular;
        font-size: 13px;
    }
    
}
.fill-form-title{
    color: $bayer-light-black;
    font-family: appRegular;
    font-size: 16px;
    width: 100%;
    margin: 15px 0 0 0;
}
#formhelper-text{
    color: $bayer-grey;
    font-family: appRegular;
    font-size: 11px
}

#select-entity-for-textfield{
    @media(min-width: 1201px) and (max-width: 1500px) {
        padding: 5.5px 14px !important;
    }
    @media(min-width: 1001px) and (max-width: 1200px) {
        padding: 4.5px 14px !important
    }
    @media(min-width: 801px) and (max-width: 1000px) {
        padding: 3.5px 14px !important
    }
    @media(max-width: 800px) {
        padding: 2.5px 14px !important
    }
}
#select-country-for-textfield{
    @media(min-width: 1201px) and (max-width: 1500px) {
        padding: 5.5px 14px !important;
    }
    @media(min-width: 1001px) and (max-width: 1200px) {
        padding: 4.5px 14px !important
    }
    @media(min-width: 801px) and (max-width: 1000px) {
        padding: 3.5px 14px !important
    }
    @media(max-width: 800px) {
        padding: 2.5px 14px !important
    }
}
