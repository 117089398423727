.MuiListItemButton-root:hover{
    background-color: transparent !important;
    color: #10384F !important;
    .MuiListItemIcon-root{
        background-color: transparent !important;
        color: #10384F !important;
    }
    
    img{
        background-color: transparent !important;
        color: white !important;
    }
}

.leftdrawer_logout{
    .MuiSvgIcon-root{
        width: 1.5vw;
        height: 1.5vw;
    }
}
.bayer_logo{
    height:3vw
}

.dashboard_icon{
    height: 1.5vw;
}
// .request_icon{
//     height: 2vw;
// }

.drawer_text{
    span{
        font-size: 1.1vw;
        font-weight: 600;
        // color: #585858;
        margin-left: -0.8vw;
    }
}
.drawer_text_color{
    span{

        color: #585858;
    }
}



