@import '../../assets/scss/colorCode.scss';


.main{
    margin: 0px 40px 20px 80px !important;
}

.main-header{
    color: black;
    // font: normal normal bold 2vw Helvetica Neue;
    font-family: 'appRegular';
    text-decoration: underline;
    text-decoration-color: #03BCFB;
    text-underline-offset: 8px;
    font-size: 1.5vw;
}

.sub-header{
    color: #323232;
    font-family: 'appRegular';
    font-size: 1.2vw;
    // font: normal normal bold 1.5vw Helvetica Neue;
    font-weight: bold;
}

.title{
    color: #96A8AE;
    margin-top: 16px;
    white-space: nowrap;
}

.value{
    margin-top: 16px;
}

.save-btn{
    margin-top: 80px !important;
    background-color: $bayer-bright-green !important;
    border-radius: 30px !important;
    border-color: transparent;
    width: 10vw;
    padding: 3px;
    color: white;
    margin-left: -6vw;

    // background-color: #89D329;
    // // width: 50%;
    // border-radius: 35px;
    // padding: 12px;
    // color: #fff;
    // font-size: 20px;
    // font-weight: 600;
    // text-transform: capitalize;
    // border: none
}

.save-btn-disabled{
    margin-top: 80px !important;
    background-color: grey !important;
    border-radius: 30px !important;
    border-color: transparent;
    width: 10vw;
    padding: 3px;
    color: white;
    margin-left: -6vw;
}

.save-btn:hover{
    background-color: $bayer-green !important;
}

.save-btn-span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.save-arrow-icon{
    width: 8%;
    justify-content: end;
    display: flex;
}

.save-btn-text{
    // width: 92%;
}


.mypf-discard-btn{
    margin-top: 80px !important;
    background-color: white !important;
    border-radius: 30px !important;
    border-color: $bayer-bright-green;
    width: 10vw;
    padding: 9px;
    color: $bayer-bright-green;
    margin-right: 8%;
}

.mypf-discard-btn:hover{
    background-color: #F7F7F7 !important;
}


.mpf-text-field{
    width: 20vw;
    margin-bottom: 20px !important;
    border-radius: 4px;
}

.mpf-text-field > .MuiInputBase-root > input{
    padding: 10.5px 14px;
}

.mpf-text-field > .MuiInputBase-root{
    height: 2em;
}

.mpf-text-field-edit-code > .MuiInputBase-root > input{
    padding: 10.5px 14px;
}
.mpf-text-field-edit-code > .MuiInputBase-root {
    height: 2em;
}
.mpf-text-field-edit > .MuiInputBase-root {
    height: 2em;
}
.mpf-text-field-edit > .MuiInputBase-root > input{
    padding: 10.5px 14px;
}

.mpf-text-field-edit-code > label{
    font-size: 12px;
}

.mpf-text-field-edit{
    width: 12vw;
    margin-right: 1vw;
    margin-bottom: 20px !important;
    border-radius: 4px;
}

.mpf-text-field-edit-code{
    width: 7vw;
    margin-right: 1vw;
    margin-bottom: 20px !important;
    border-radius: 4px;
    margin-right: 1vw !important
}

// .mpf-text-field-edit > .MuiInputBase-root > p{
//     position: absolute;
//     white-space: nowrap;
//     top: 2vh;
//     left: 12vw;
// }
.mpf-text-field-edit { 
.css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    white-space: nowrap;
    top: 2vh;
    left: 12vw;
}
}

.mpf-text-field-edit-email{ 
    width: 20vw;
    margin-right: 1vw;
    margin-bottom: 20px !important;
    border-radius: 4px;
    .css-1wc848c-MuiFormHelperText-root.Mui-error {
        position: absolute;
        white-space: nowrap;
        top: 2vh;
        left: 20vw;
    }
    .MuiInputBase-formControl .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 3vh;
    }
    }
    .mpf-text-field-edit-email > .MuiInputBase-root{
        height: 2em;
    }

.confirm-btn-myprofile{
    text-transform: capitalize !important;
}