@import '../../assets/scss/colorCode.scss';

.table__row:hover{
    cursor: pointer;
    background-color: #f4f4f4;

}

.table__row{
    background-color: #FAFAFA;
}

.main-header {
    color: black;
    // font: normal normal bold 2vw Helvetica Neue;
    font-family: 'appRegular';
    text-decoration: underline;
    text-decoration-color: $bayer-underline-header;
    text-underline-offset: 8px;
    text-align: left;
}

.certi__req__table_header {
    background-color: $bayer-light-green;
    .heading{
        // font: normal normal bold 1.2vw Helvetica Neue;
        font-family: 'appBold';
        background-color: $bayer-light-green ;
        font-size: 1.1vw;
        max-width: 9vw;
        vertical-align: text-top;
        p{
            color: #707070;
    font-size: .8vw;
        }
    }
}

.table-footer {
    text-align: left;
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
    .faded{
        color: $bayer-dark-grey;
    }
}

.trader-active {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-light-green;
    color: $bayer-green;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.trader-inactive {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-light-red;
    color: $bayer-red;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.trader-pending {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-sky-blue;
    color: $bayer-light-blue;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.deactivate-btn {
    color: #e92c41; 
    border: 0px;
    background-color: #FAFAFA;
    cursor:pointer;
}

.deactivate-btn-disabled {
    border: 0px;
    background-color: #FAFAFA;
}

.filter-tag{
    margin: 0em 1em;
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
}
.certificate-list{
    background-color: #FAFAFA;
    border: 0.5px solid #CCD6D9;
    border-radius: 12px;

    .MuiOutlinedInput-root{
        height: 6vh;
        width: 23vw;
        font-size: 0.9vw;
    }

    .MuiTablePagination-selectLabel{
        // font: normal normal normal 1vw Helvetica Neue !important;
        font-family: 'appRegular' !important;
        font-size: 1vw;
    }
    
    .MuiTablePagination-displayedRows{
        // font: normal normal normal 1vw Helvetica Neue !important;
        font-family: 'appRegular' !important;
        font-size: 1vw;
    }
}

.certi__req__table {
    box-shadow: none !important;
    background-color: #FAFAFA !important;

    .MuiTableCell-footer {
        border: none;
        border-top: 0.5px solid #CCD6D9;
    }

    .MuiTablePagination-select{
        text-align: center !important;
    }
}

.certificate-request-table-body{
    height: 100%;
    
    td{
        padding: 8px 16px;
        // font: normal normal normal 1vw Helvetica Neue;
        // font-family: 'appRegular';
        font-size: 1vw;
        color: #585858;
        max-width:8vw
    }

    .MuiToolbar-root{
        min-height: 2vw !important;
        height: 2vw;
        overflow: hidden;
    }
}

.filter-indicator{
    width: 0.5em !important;
    height: 0.5em !important;
    margin: -1.4vh 0vh 0vh -1.2vh;
    color: #D0021B;
}

.error-stack{
    width: 100%;
    align-items: center;

    .error-img{
        height: 12vw;
        padding: 1vw 0vw;
    }

    .error-main{
        color: #707070;
        font-size: 1.2vw;
        font-family: 'appBold';
    }

    .error-sub{
        color: #004422;
        font-size: 1vw;
        font-family: 'appBold';
    }
}

.identifier-and-tnc-container{
    display: inline-flex;
    flex-direction: column;
    
    .tnC-container{
        display: flex;
        justify-content: flex-end;
        .tnC{
            color: $bayer-dark-blue;
            font-size: 1vw;
            text-decoration: underline;
        }
        .tnC:hover{
            cursor: pointer;
        }
    }
}
.certi_identifier{
    color: #0091DF;
}

.certi_identifier:hover{
    cursor: pointer;
    
}

.identifier__trader_detail{
    width: 25%;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    .trader_name_head{
            color: #96A8AE;
            font-size: .8vw;
    }
    .trader_name{
        color: #323232;
        font-size: .9vw;
        font-weight: 600;
    }
    .identifier__trader_icon{
        width: 3vw;
        height: 3vw;
        border-radius: 3vw;
        background-color: #004422;
        opacity: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        img{
            height: 2vw;
        }
    }
}
.search-box-swk{
    display: flex;
}
.docs_img{
    width: 5vw;
    height: 5vw;
    border-radius: 5vw;
    background-color: #89D32920;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        height: 3vw;
    }
}

.docs_img_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    div{
        text-align: center;
    }
}

.head_certi_num{
    background-color: #004422;
    width: 11%;
    display: flex;
    color: #fff;
    border-radius: 5px;
    padding: 4px;
    align-items: center;
    justify-content: center;
    width:9vw;
    p{
        font-size: 1vw;
    }
}

.identifier__bottom{
    display: flex;
    justify-content: space-around;
    position: relative;
}

.bottom_details:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    height: 100%;
    height: inherit;
    border-left: 2px dotted lightgrey;
}

.bottom__detail_icon{
    img{
        margin-right: 10px;
        height: 1vw;
    }
    span{
        width: 15vw;
    word-wrap: break-word;
    }
    p,span{
        font-size: .9vw;
        color: #323232;
        display: block;
    }
}

.Filter-btn-summary {
    cursor: pointer !important;

    .css-1b47e06{
        cursor: pointer !important;
    }
}

.identifer-modal{
    
}
.css-hh8la8-MuiGrid-root .css-1awqxnd{
    width:100% !important
}

.identifier__top_title{
    // width: 25vw;
    // margin-top: -7vh;
    // margin-left: -2vw;
    h2 {
        width: 26.5vw; 
        text-align: center; 
        border-bottom: 2px dashed #89D329; 
        line-height: 0.1em;
        // margin: 10px 0 20px; 
        font-size: 1vw;
        color: #585858;
        span { 
            background:#fff; 
            padding:0 10px; 
        }
     } 
     
     
}
.subtext{
    color: #777777;
    font-size: .8vw;
}

.subtext_date{
color: #000000;
font-size: 1vw;
}

.bottom_text{
    font-size: 1vw;
    color: #585858;
}

.status_pending{
    display: flex;
    background-color: #0091DF20;
    padding: 5px;
    border-radius: 20px;
    color: #0091DF;
    justify-content: center;
    font-size: 1vw;
    align-items: center;
    text-transform: capitalize;

    p{
        margin-left: 5px;
    }
}


.status_declined{
    display: flex;
    background-color: #D0021B20;
    padding: 5px;
    border-radius: 20px;
    color: #D0021B;
    justify-content: center;
    font-size: 1vw;
    text-transform: capitalize;

    p{
        margin-left: 5px;
    }
}

.status_issued{
    display: flex;
    background-color: #66B51220;
    padding: 5px;
    border-radius: 20px;
    color: #66B512;
    justify-content: center;
    font-size: 1vw;
    text-transform: capitalize;

    p{
        margin-left: 5px;
    }
}

.status_expired{
    display: flex;
    background-color: #3E3E3E20;
    padding: 5px;
    border-radius: 20px;
    color: #3E3E3E;
    justify-content: center;
    font-size: 1vw;
    text-transform: capitalize;

    p{
        margin-left: 5px;
    }
}


    .close-icon-container {
        color: #0091df;
        width: 35px;
        height: 35px;
        position: absolute;
        top: -7vh;
        right: 0;
    
    .edit-modal-image-container{
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }}

    .close-icon-container :hover{
        cursor: pointer;
    }



@media screen and (min-width: 1922px) {
    .certificate-list{
        height: 71vh;
    }
    .certi__req__table {
        height: 57vh;
    }
    .export-btn{
        width: 6vw !important;
    }
}

@media screen and (max-width: 1920px) {
    .certificate-list{
        height: 73vh;
    }
    .certi__req__table {
        height: 57vh;
    }
}

@media screen and (max-width: 1707px) {
    .certificate-list{
        height: 74vh;
    }
    .certi__req__table {
        height: 60vh;
    }
}

@media screen and (max-width: 1600px) {
    .certificate-list{
        height: 73vh;
    }
    .certi__req__table {
        height: 59vh;
    }
    .export-btn{
        padding-bottom: 6px;
    }
}

@media screen and (max-width: 1423px) {
    .certificate-list{
        height: 73vh;
    }
    .certi__req__table {
        height: 59vh;
    }
    .export-btn{
        padding: 0px;
    }
}

@media screen and (max-width: 1280px) {
    .certificate-list{
        height: 71vh;
    }
    .certi__req__table {
        height: 57vh;
    }
    .export-btn{
        padding-bottom: 5px;
    }
    .certificate-request-table-body td {
        padding: 8px 8px;

    }
}

@media screen and (max-width: 1165px) {
    .certificate-list{
        height: 71vh;
    }
    .certi__req__table {
        height: 57vh;
    }
}

@media screen and (max-width: 1024px) {
    .certificate-list{
        height: 70vh;
    }
    .certi__req__table {
        height: 56vh;
    }
}

.new__req__btn{
    font-family: 'appRegular';
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    padding: 7px 20px;
    background-color: white;
    border: 2px solid #10384f !important;
    color: $bayer-dark-blue;
    position:relative;
    width: 14vw;
    height: 6vh;
    cursor:pointer;
    font-size: 0.9vw;
}

.export-icn{
margin-left: 10px;
height: 2.2vh;
}

.action__icon{
    width: 1.5vw !important;
    margin-left: 7px;

}
.action__icon_active{
    cursor: pointer;
}

.action__cell{
    display:flex !important;
    padding: 2.9vh 16px !important;

}


.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
    color: #89D329;
  }

.tooltip-wrap {
    position: relative;
  }
  .tooltip-wrap .tooltip-content {
    border: 1px solid $bayer-light-blue;
    display: none;
    position: absolute;
    bottom: 5%;
    left: 5%;
    right: 5%;
    background-color: #fff;
    padding: .5em;
    width: 16vw;
    max-height: 20vh;
    z-index: 100;
    font-family: 'appRegular';
    font-size: 2vh;
    line-height: 2.5vh;
    overflow-y: auto;
    cursor: context-menu;
  }
  .tooltip-wrap:hover .tooltip-content {
    display: block;
  }

  .filter-indicator{
    width: 0.5em !important;
    height: 0.5em !important;
    margin: -1.4vh 0vh 0vh -1.2vh;
    color: #D0021B;
}