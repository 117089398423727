/** @format */

@import '../../assets/scss/colorCode.scss';

.dashboard-container {
    padding: 0vh 4vw 0vh 8vw;
    color: $bayer-blue;
    // .page-title-container {
    //     display: flex;
    //     flex-direction: column;

    //     .page-title {
    //         font-family: appBold;
    //         display: inline;
    //     }

    //     .underlying-container {
    //         color: $bayer-underline-header;
    //     }
    // }
    .page-title {
        font-family: appBold;
        font-size: 1.5vw;
        color: $bayer-black;
        text-decoration: underline;
        text-decoration-color: $bayer-underline-header;
        text-underline-offset: 8px;
    }
    .metric-container-box {
        margin-top: 5vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 10px;
        row-gap: 10px;
        @media(max-width: 933px) {
            justify-content: space-evenly;
        }
    }
}
