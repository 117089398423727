/** @format */

.error-txt {
    color: #d0021b !important;
    display: flex;
    padding-left: 3px;
    // align-items: center;
    label {
        padding-left: 5px;
    }
}
