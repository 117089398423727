.noti-panel{
    height: 100vh;
    width: 30vw;
    position: absolute;
    right: 0px;
    background-color: white;
    padding: 0.1vw 1.5vw 1.5vw;
    overflow-y: auto;
    outline: none;

    p{
        font-family: 'appRegular';
        font-size: 1vw !important;
    }

    .header{
        display: flex;
        position: sticky;
        top: 0;
        background-color: white;
        padding-top: 1.5vw;
        align-items: center;
        z-index: 100;
        // border-bottom: 1px solid #96A8AE;
    }

    .time-label{
        color: #96A8AE;
        padding: 0.3vw 0vw ;
    }
}

.date-group-div {
    padding-top: 1vw;

    .date-label{
        color: #585858;
    }

    
}

.certi-req-noti {
    border: 1.5px solid #0091DF;
    border-left: 4px solid #0091DF;
    border-radius: 5px;
    display: flex;
    padding: 0.6vw;
    margin-top: 1vw;
}

.register-req-noti {
    border: 1px solid #96A8AE;
    border-radius: 5px;
    display: flex;
    padding: 0.6vw;
    margin-top: 1vw;
}
