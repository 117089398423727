@import '../../../../assets/scss/colorCode.scss';
.cc_req_certi__req__table{
    height: 57vh
}
.countries-container{
    display: flex;
    column-gap: 4px;
    row-gap: 4px;
    flex-wrap: wrap;

    .country-name-container{
        background-color: $bayer-dark-green;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        border-radius: 10px;
        // height: 32px;
        color: #FFFFFF;
    }
}

.edit-and-delete-images-container{
    display: flex;

    .image-container{
        padding: 4px;
        border-radius: 50%;
        border: 2px solid $bayer-dark-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        cursor: pointer;
        .image{
            width: 14px;
            height: 14px;
        }
    }

}
