/** @format */
@import './assets/scss/colorCode.scss';

* {
    margin: 0;
    padding: 0;
}

.Toastify__toast-container{
    max-width: 75vw !important;
    width:30vw !important
}

.close-icon-container{
    color: $bayer-light-blue;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    // margin-bottom: 1;
    .edit-modal-image-container{
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
