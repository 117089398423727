@import '../../assets/scss/colorCode.scss';

.header-top{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5%;
}

.help-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px;
    border-radius: 59px;
    border-color: #585858;
    width: 5.5vw;
    margin-bottom: 1vh;
    margin-left: 0.5vw;

    p{
        margin: 5px 0px;
    }
}

.header-icon {
    margin: 0.8vw;
    cursor: pointer;
    font-size: 2vw;
}

.noti-count-label{
    height: 1.2vw;
    width: 1.2vw;
    border-radius: 1.5vw;
    background-color: $bayer-red;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: white;
    font-size: 0.6vw;
    margin-left: -1.8vw;
    margin-top: 0.5vw;
}