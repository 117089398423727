@import '../../../../assets/scss/colorCode.scss';

.popup-container{
    width: 80vw;

    .tnC-modal-popup{
        background-color: white;
        border-radius: 20px;
        height: 80vh;
        position: relative;
        .arrows-and-tnccontainer{
            display: flex;
            align-items: center;
            padding: 1vh 2vw;

            .tnc-container{
                height: 100%;   
                width: 100%;
                .tnC-title{
                    color: $bayer-light-black;
                    font-family: appBold;
                    font-size: 16px;
                }
                
                .scroll-container{
                    overflow-y: scroll;
                    padding: 1vh 0;
                    background-color: $bayer-grey-background-tnc;
                    border: 2px solid #aaaaaa;
                    border-radius: 8px;
                    padding-left: 4px;
                    .tnC-content{
                        color: $bayer-light-black;
                        font-family: appRegular;
                        font-size: 14px;
                        white-space: pre-wrap
                    }
                }
                .scroll-container-normal{
                    height: 80%;
                }
                .scroll-container-enlarge{
                    height: 90%;
                }
                .consent-container{
                    display: inline-flex;
                    cursor: pointer;
                    align-items: center
                };

                .arrow-container{
                    display: flex;
                    justify-content: space-between;
                    margin: 1vh 0;
                    
                    .arrow-card{
                        color: white;
                        background-color: $bayer-bright-green;
                        padding: 5px;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        .arrow{
                            width: 12px;
                        }
                        .direction-title{
                            font-family: appRegular;
                            font-size: 14px;
                        }
                    }
                }
            }
            .tnc-container-margin{
                margin: 0 3%;
            }
        }
        .arrows-and-tnccontainer-normal{
            height: 92%;    //earlier 95%
        }
        .arrows-and-tnccontainer-enlarge{
            height: 100%
        }
        .confirm-container{
            position: absolute;
            bottom: 0;
            width: 100%;
            color: white;
            display: flex;
            justify-content: center;
            border-radius: 0 0 20px 20px;
            height: 8%;
            align-items: center;
            font-family: appRegular;
        }
        .confirm-container-enabled{
            background-color: $bayer-bright-green;
            cursor: pointer;
        }
        .confirm-container-disabled{
            background-color: $bayer-medium-grey;
            cursor: not-allowed;
        }
    }
}