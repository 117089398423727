@import '../../assets/scss/colorCode.scss';

.filter-popup{
    // font: normal normal normal 1vw Helvetica Neue;
    // font-family: 'appRegular';

    .MuiFormControlLabel-label{
        // font: normal normal normal 1vw Helvetica Neue !important;
        font-family: 'appRegular' !important;
    }
}

.clear-filter{
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
    color: $bayer-light-blue;
    border: none;
    background-color: white;
    cursor: pointer;
}

.status-checkbox{
    color: $bayer-light-blue;
}

.discard-btn {
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
    padding: 0.8vh 2vh !important;
    border-radius: 80px !important;
    border-color: $bayer-dark-blue !important;
    color: $bayer-dark-blue !important;
    background-color: white;
    width: 6vw;
    border: 1px solid !important;
}

.apply-btn {
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
    padding: 1vh 2vh;
    border-radius: 80px;
    border: none;
    color: white;
    background-color: $bayer-bright-green;
    width: 6vw;
}

.bayer-font{
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
}

// .MuiList-root{
//     max-height: 30% !important;
//     overflow: auto !important;
// }

