/** @format */
@import '../../assets/scss/colorCode.scss';

.blue-color {
    background-color: $bayer-underline-header-20opac;
}
.green-color {
    background-color: $bayer-bright-green-20opac;
}

.metric-container {
    width: 267px;
    height: 166px;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    // opacity: 0.5;
    .metric-data-container {
        padding: 20px 26px;
        .metric-image-name-container {
            display: flex;
            align-items: center;
            column-gap: 8px;
            .metric-label {
                font-family: appRegular;
                font-size: 16px;
                color: $bayer-blue;
            }
        }
    }

    .metric-underlying-container {
        position: absolute;
        bottom: -6px;
        // height: 3px;
        // width: 100%;
    }
}
