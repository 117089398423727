
@import '../../assets/scss/colorCode.scss';



.registration__container{
    display: flex;
    flex-direction: row;
    h2{
        color: $bayer-light-black;
    font-family: 'appRegular';
    margin-top: 20px;

    }
    // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        // height: 1.5vh;
    // }
    // .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        // height: 1.3vh;
        // min-height: 1.3vh;

    // }
    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        font-size: .9vw;
    }
}

.country_list_drop{
    font-size: 1.1vw !important;
}
.details_country{
    width: 96%;
    // margin-bottom: 1.4vh !important;
    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 5px 6px !important;
        font-size: 1vw;
        padding-left: 0.7vw !important;
    }
}

.regis__country{
    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input .css-182didf{
        padding: 5px 6px !important;
        font-size: 1vw;
        display: flex;
    padding-left: 0.8vw !important;
    }
}

.custom-mui-textField{

//     input::-webkit-input-placeholder,
// textarea::-webkit-input-placeholder {
//   color: #FF9900 !important;
// }
::placeholder {

    font-size: .75rem;
    padding: 5px;
    line-height: 1;
    box-sizing: border-box;
  color: #96A8AE !important;

  }
    
}

.details__form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    row-gap: 20px;
    // margin-bottom: 1vh !important;
    .css-1wc848c-MuiFormHelperText-root.Mui-error {
        font-size: .7vw;
        position: absolute;
    top: 5vh;
    width: 100%;
    margin-left: 0;
    }

}


.registration__posterImage{
    width: 60%;
}

.registration__left{
    // width: 60%;
    background-color: $bayer-bright-green-20opac;
}
.personal__detail{
    color: $bayer-light-black;
}

.registration__form{
    // width: 40%;
    padding: 5% 3% 0% 4% !important;
    h1{
        font-family: 'appRegular';
        font-size: 2vw;

        color: $bayer-grey-one;
        margin-bottom: 20px;
    }
    h2{
        font-size: 1.4vw;
    }
    
}

.register__btn{
    margin-top: 80px !important;
    background-color: $bayer-grey !important;
    border-radius: 30px !important;
    
}

.country_label{
    font-size: .9vw;
}

.registration__text__div{
    width: 75%;
    // background-color:$bayer-mild-green ;
    min-height: 90vh;

    // position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    overflow: hidden;
    color: #fff;
      z-index:1;
    //   padding-top: 3em;
      padding-left: 3em;
      
}

.registration__text__div::before {
    content: '';
    position: absolute;
    top: 8vh; left: 0;
    width: 30%; height: 92vh;
    background: $bayer-mild-green;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(355deg);
    -ms-transform: skew(355deg);
    transform: skew(355deg);
    z-index:-1;
  }



  .registration__logo {
    height: auto;
    width:99%;
  }
 

.btn__register{
    background-color: #89D329;
    width: 50%;
    border-radius: 35px;
    padding: 1vh;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    border: none

}

.submitbtn__wrapper{
    display: flex;
    justify-content: center;
    margin-top:60px;
    .btn__regis__icon{
        width: 8%;
        justify-content: end;
        display: flex;
        .css-i4bv87-MuiSvgIcon-root{
            height: 2.5vh;
        }
    
    }
    .btn__regis{
        display: flex;
        width:100%;
        align-items:center
    }
    .btn__regis__text{
        width: 92%;
    }
}

.left__bar{
    width: 1%;
    height: 100vh;
    background-color: $bayer-mild-green;
}

.user__guide{
    position: absolute;
    bottom: 75px;
    left: 0px;
    width: 12%;
    height: 6vh;
    background-color: $bayer-mild-green;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
    color: #fff;
    h3{
        font-size: .8vw;
        font-family: 'appRegular';
        margin-right: 5px;

    }
}

.user__guide:hover{
    cursor: pointer;
}

.submit__register{
    background-image: linear-gradient(108deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 83%, #66B512 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;

}

.submit__register:hover{
    cursor: pointer;
    background-color: #66B512;

}

.submit__register_disabled{
    background-image: linear-gradient(108deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 83%, #71726f 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
}

.submit__register_disabled:hover{
    cursor: not-allowed;
}

.register__arrow_icon{
}



.guide_icon{
    width: 1.5vw;
}
@media only screen and (max-width: 1423px) {
    .registration__form {
        padding: 40px 50px 75px 50px;
        h1{
            margin-bottom: 0px;
        }
    }
    .regis__country{
        button{
            padding: 8.5px 5px;

        }
    }
    
  }

@media only screen and (max-width: 1707px) {
    
    .registration__form {
        padding: 65px 50px 75px 50px;
    }

    .registration__form h1 {
        margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 1600px) {
    
    .registration__form {
        padding: 45px 50px 75px 50px;
    }

    .registration__form h1 {
        margin-bottom: 5px;
    }

    .submitbtn__wrapper {
        margin-top: 40px;
    }
    .submit__register{
        padding:8px !important
    }
  }

  @media screen and (max-width: 1280px) {
    .registration__form {
        .makeStyles-form-1 {
            // gap:12px
        }
    }
}

@media screen and (max-width: 1423px) {
    .registration__form {
        .makeStyles-form-1 {
            // gap:20px
        }
    }
}


@media screen and (max-width: 1164px) {
    .registration__form {
        .makeStyles-form-1 {
            // gap:10px
        }
    }
}
  @media only screen and (max-width: 1280px) and (min-width: 1024px) {
    .registration__text__div::before {
        top: 8vh;
    }

    
    // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    //     padding: 10px !important;
    // }
    // .details__form {
    //     row-gap: 7px;
    // }

    // .registration__container h2 {
    //     margin-top: 10px;
    // }

    // .submitbtn__wrapper {
    //     margin-top: 20px;
    // }
    // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    //     padding: 4px 14px !important;
    // }
  }

  @media only screen and (max-width: 2560px) and (min-width: 1920px) {
    .registration__text__div::before {
        top: 8vh;
    }
  }

  @media only screen and (min-width: 1707px) {
    
    .details__form{

        .css-1wc848c-MuiFormHelperText-root.Mui-error {
            font-size: .6vw;
            position: absolute;
        top: 4vh;
        width: 100%;
        margin-left: 0;
        }
    
    }
  }

