/** @format */

@import '../../assets/scss/colorCode.scss';

.container {
    display: flex;
    height: 92vh;
    .login-header {
        .register-or-login {
            position: absolute;
            right: 15px;
            top: 30px;

            .register-or-login-text {
                font-size: 16px;
                font-family: appRegular;
                color: #10384f;
                margin-right: 10px;
            }
        }
    }

    // .image-container {
    //     width: 60%;
    .intl-img-container{
        width: 100%;
        height: 100%;
        position: relative;
        .intl-image{
            // width: 100%;
            height: 100%;
            position: absolute;
        }
        
    }
    
    // }

    .login-and-role-container {
        // width: 40%; //Due to box width of image
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .bottom-condition-text {
            // font-family: appRegular;
            color: $bayer-dark-blue;
        }

        .role-changer {
            // font-family: appRegular;
            color: $bayer-light-blue;
            padding-left: 10px;
            cursor: pointer;
        }
    }
}

.login-or-register-button {
    border-radius: 30px;
    background-color: #585858;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    font-family: appBold;
}
