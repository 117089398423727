.grid-light{
    background-color: #FAFAFA !important;
    min-height: 200px;
    border-radius: 4px !important;
    width: 41.5vw;
    overflow-y: auto;
    height: 57vh;
}


.search-text-field > .MuiInputBase-root{
    height: 2em;
}

.no-items{
    margin-left: 16vw;
    position: relative;
    top: 22vh;
}

#help-training-video-link{
    text-decoration: underline;
    color: #1f9ee3;
}
#help-training-video-link:hover{
    cursor: pointer;
}