


.service-inc-popup-close{
    display: flex;
    padding: 8px;
    justify-content: space-around !important;
    margin-top: 25vh;
    margin-left: 35vw;

}

.required::after {
    content: ' *';
    color: red;
  }

.service-inc-popup-close-case{
justify-content: space-around !important;
margin-top: 0vh;
margin-left: 81vw;
}

