.details-header{
    // background: white;
    font-size: 0.8vw;
    display: flex;
    padding: 0.3vw;
}

.case-comments-section{
    .css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root{
        padding: 0.4vw 0vw 0.4vw 0.4vw;
    }
    display: flex;
    flex-direction: column;
    margin: 0.5vw;
    padding: 0.5vw;
    border: 1px solid rgb(101 112 116);
    border-radius: 5px;
    height: 160px;
    width: 100%;
    overflow: auto;
    padding: 0.2vw;
}