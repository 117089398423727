@import '../../assets/scss/colorCode.scss';


.main-case-creation{
    margin: 0px 40px 20px 80px !important;
}

.main-header{
    color: black;
    // font: normal normal bold 2vw Helvetica Neue;
    font-family: 'appRegular';
    text-decoration: underline;
    text-decoration-color: #03BCFB;
    text-underline-offset: 8px;
    font-size: 1.5vw;
}

.asterik-case{
    display: flex;
    font-size: 1vw;

}

.case-dropdown-menu{
    font-size: 1vw  !important;
    
}

.case-detail{
    // .MuiTextField-root input {
    // padding: 0.4vw !important;
    // font-size: 1vw !important;
    // }

    .case-detail-desc {
        .MuiOutlinedInput-root{
        padding: 0.5vw;
        }
        input{
            padding: 0.2vw;
        }
    }
}

.sub-header{
    color: #323232;
    font-family: 'appRegular';
    font-size: 1.2vw;
    // font: normal normal bold 1.5vw Helvetica Neue;
    font-weight: bold;
}

.title{
    color: #96A8AE;
    margin-top: 16px;
    white-space: nowrap;
    font-size: 1.1vw;
}

.desc-box-case{
    width: 100%;
    margin-top: 10px;
    .MuiTextField-root{
        width:100% !important
    }
}

.value{
    margin-top: 16px;
    font-size: 1.1vw;
    color: black;
}

.ml-1{
    margin-left:1vw ;
}

.dropdown-case{
    display: flex;
    flex-direction: column;
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        font-size: 1vw;
        width: 90%;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        padding: 10px;
    }

    .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{
        padding: 9px 0px 9px 6px;
        font-size: 1vw;
    }

    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
        font-size: 1vw;
    }

    .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
        padding: 0px !important;
    }

    .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 9px 9px 9px 5px;
    }
}

.desc-case{
    .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
        padding: 10px;
    }
}

.save-btn{
    margin-top: 80px !important;
    background-color: $bayer-bright-green !important;
    border-radius: 30px !important;
    border-color: transparent;
    width: 10vw;
    padding: 3px;
    color: white;
    margin-left: -6vw;

    // background-color: #89D329;
    // // width: 50%;
    // border-radius: 35px;
    // padding: 12px;
    // color: #fff;
    // font-size: 20px;
    // font-weight: 600;
    // text-transform: capitalize;
    // border: none
}

.save-btn-disabled{
    margin-top: 80px !important;
    background-color: grey !important;
    border-radius: 30px !important;
    border-color: transparent;
    width: 10vw;
    padding: 3px;
    color: white;
    margin-left: -6vw;
}

.save-btn:hover{
    background-color: $bayer-green !important;
}

.save-btn-span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.save-arrow-icon{
    width: 8%;
    justify-content: end;
    display: flex;
}

.save-btn-text{
    // width: 92%;
}

.details-box{
    display: flex;
    flex-direction: row;

    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
        max-height: -webkit-fill-available !important;
    }
   
}
.confirm-btn-case-req{
    text-transform: capitalize !important;
}
.first-box{
    margin-right: 15vw;
}
.second-box{
    margin-top: 8vh;
}

.text-input{
    margin-bottom: 5vh;
    margin-top: 2vh;
    height: 6vh;
}

.mpf-text-field{
    width: 20vw;
    margin-bottom: 20px !important;
    border-radius: 4px;
}

.mpf-text-field > .MuiInputBase-root > input{
    padding: 10.5px 14px;
}

.raised-button-file-div > label > span{
    margin-top: 3vh;
    border: 1px solid #1976db;
}

.select-placeholder{
    color: #96A8AE;
    opacity: .5;
    input{
        ::placeholder {
            color: #96A8AE;
            opacity:1
          }
    }
}

.time-access{
    color: #888888;
    cursor: pointer;
}