@import '../../assets/scss/colorCode.scss';

.deactivate-popup{
    
    .MuiFormLabel-root{
        font-family: 'appRegular';
        font-size: 1vw;
    }

    // .MuiFormLabel-root::first-letter{
    //     color: red;
    // }

    .MuiSelect-select{
        font-family: 'appRegular';
        font-size: 1vw;
    }

    .MuiInputBase-root{
        font-family: 'appRegular';
        font-size: 1vw;
    }

    .MuiDialogActions-root{
        font-family: 'appRegular';
        padding: 8px 8px 0px 8px;
    }

    .MuiMenuItem-root{
        color: #323232 !important;
        font-family: 'appRegular';
        font-size: 1vw;
    }
}

.popup-title{

    padding: 0vw 2vw !important;
    
    .MuiTypography-root {
        font-weight: bold;
        font-size: 1.2vw !important;
        color: #323232;
        text-align: center;
        padding-bottom: 1vw;
    }
}

.popup-warning{

    padding: 0vw 2vw !important;
    
    .MuiTypography-root {
        font-weight: bold;
        font-size: 1vw !important;
        color: #323232;
        padding-bottom: 1vw;
        text-align: center;
        white-space: pre-line;
    }
}

.popup-content{
    padding: 1vw 2vw !important;
}

.starlabel label:after {
    content:" *";
    color: red;
  }
