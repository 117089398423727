@import '../../../../assets/scss/colorCode.scss';
.popup-container{
    width: 75vw;
    
    .close-icon-container{
        color: $bayer-light-blue;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        .edit-modal-image-container{
            background-color: white;
            border-radius: 50%;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .modal-popup{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        padding: 2vw;
        row-gap: 4vh;

        .edit-row-container{
            // display: block;
            width: 100%
        }
        .popup-dropdowns-container{
            width: 100%;
            .dropdown{
                width: 80%
            }
            .edit-modal-countries-container{
                display: flex;
                flex-direction: column;
                row-gap: 2vh;
                .countrychip-container{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                    .countrychip {
                        background-color: $bayer-dark-green;
                        color: #fff;
                        display: inline-flex;
                        padding: 5px 10px;
                        border-radius: 30px;
                        // margin: 0 10px 10px 0;
                        justify-content: center;
                        align-items: center;
                        .countrychip-label {
                            // margin: 0 10px 0 0;
                            display: flex;
                            z-index: 1;
                            align-items: center;
                            //added
                            column-gap: 5px;
                            font-family: appRegular;
                            font-size: 12px
    
                        }
                    }
                }
            }
        }
        .editmodal-buttons-container{
            display: flex;
            column-gap: 15px;
            .arrow{
                position: absolute;
                right: 0.5vw;
            }
            .apply {
                display: flex;
                justify-content: center;
                .submit__register {
                    background-image: linear-gradient(
                        108deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 0) 83%,
                        #66b512 50%
                    );
        
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .btn__regis__icon {
                        width: 8%;
                        justify-content: end;
                        display: flex;
                    }
                    .btn__regis {
                        display: flex;
                        width: 100%;
                        align-items: center;
                    }
                    .btn__regis__text {
                        width: 92%;
                        font-family: appMedium;
                        font-size: 12px;
                    }
                }
                .submit__register:hover {
                    cursor: pointer;
                    background-color: #66b512;
                }
            }
        }
        .error-container{
            color: $bayer-red;
            font-family: appRegular;
            font-size: 13px;
            width: 100%
        }
    }
}
