@import '../../assets/scss/colorCode.scss';

.filter-popup{
    // font: normal normal normal 1vw Helvetica Neue;
    // font-family: 'appRegular';

    .MuiFormControlLabel-label{
        // font: normal normal normal 1vw Helvetica Neue !important;
        font-family: 'appRegular' !important;
        font-size: .9vw;
    }
    .filter-title{
        font-size: 1vw;
    }
}

.clear-filter{
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
    color: $bayer-light-blue;
    border: none;
    background-color: white;
    font-size: 1vw;
    cursor: pointer;
}

.MuiPickersDay-root{
    padding: 4px;
}

.status-checkbox{
    color: $bayer-light-blue !important;
    
}

.discard-btn-certificate {
    font-family: 'appRegular';
    padding: 1vh 2vh !important;
    border-radius: 80px !important;
    border-color: $bayer-dark-blue !important;
    color: $bayer-dark-blue !important;
    background-color: white;
    width: 90%;
    border: 1px solid !important;
}

.apply-btn-certificate {
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
    padding: 1.6vh 2vh;
    border-radius: 80px;
    border: none;
    color: white;
    background-color: $bayer-bright-green;
    width: 90%;
}

.apply-btn-certificate:hover{
    cursor: pointer;
}

.bayer-font{
    // font: normal normal normal 1vw Helvetica Neue;
    font-family: 'appRegular';
}

.filter_dropdown{

        font-size: 1vw !important;

}


.date-picker-filter{
    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 1vw;
        line-height: 1.5rem;

    }
     
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        font-size: .8vw;
    }
    .css-i4bv87-MuiSvgIcon-root,.css-i4bv87-MuiSvgIcon-root{
        width: 1.5vw;
        height: 1.5vw;

    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2 {
        padding: .7vw .5vw !important;
        font-size: 0.7vw;
        text-transform: uppercase;
        color: #96A8AE;
        opacity:1
    }
    .css-1laqsz7-MuiInputAdornment-root, .css-1nvf7g0  {
        margin-left: -10px;
    }
}

.select-placeholder{
    color: #96A8AE;
    opacity: .5;
    input{
        ::placeholder {
            color: #96A8AE;
            opacity:1
          }
    }
}

@media screen and (max-width: 1920px) {
    .date-picker-filter{
        
        // .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        //     padding: 14px 6px;
        // }
    }
}

@media screen and (max-width: 1708px) {
    .date-picker-filter{
        
        // .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        //     padding: 12px 5px;
        // }
    }
}

@media screen and (max-width: 1600px) {
    .date-picker-filter{
        
        // .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        //     padding: 10px 4px;
        // }
    }
}

@media screen and (max-width: 1423px) {
    .date-picker-filter{
        
        // .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        //     padding: 8px 4px;
        // }
    }
    .status-checkbox{
        .css-i4bv87-MuiSvgIcon-root{
            width: .8em;
        }
    }
}

@media screen and (max-width: 1423px) {
    .apply-btn-certificate {
        padding: 1.3vh 2vh;
    }
    .discard-btn-certificate {
        padding: 1vh 2vh;
    }
}

@media screen and (max-width: 1280px){
    .status-checkbox{
        .css-i4bv87-MuiSvgIcon-root{
            width: .7em;
        }
    }
}