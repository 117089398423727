@import '../../assets/scss/colorCode.scss';

.table__row:hover{
    cursor: pointer;
    background-color: #f4f4f4;

}

.table__row{
    background-color: #FAFAFA;
}

.trader-list-container{
    padding: 0vh 4vw 4vh 8vw;
}

.main-header {
    color: black;
    font-family: 'appRegular';
    font-size: 1.5vw;
    text-decoration: underline;
    text-decoration-color: $bayer-underline-header;
    text-underline-offset: 8px;
    text-align: left;
    font-weight: bold;
}

.trader-list-table-header {
    background-color: $bayer-light-green;
    .heading{
        font-family: 'appBold';
        font-size: 1vw;
        background-color: $bayer-light-green ;
    }
}

.table-footer {
    text-align: left;
    font-family: 'appRegular';
    font-size: 1.2vw;
    font-weight: normal;
    .faded{
        color: $bayer-dark-grey;
    }
}

.trader-active {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-light-green;
    color: $bayer-green;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.trader-inactive {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-light-red;
    color: $bayer-red;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.trader-pending {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-sky-blue;
    color: $bayer-light-blue;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.deactivate-btn {
    color: #e92c41; 
    border: 0px;
    background-color: #FAFAFA;
    cursor:pointer;
}

.deactivate-btn-disabled {
    border: 0px;
    background-color: #FAFAFA;
}

.filter-tag{
    margin: 0em 1em;
    font-family: 'appBold';
    font-size: 1.2vw;
}
.trader-list{
    background-color: #FAFAFA;
    margin-top: 2vw;
    border: 0.5px solid #CCD6D9;
    border-radius: 12px;

    .MuiOutlinedInput-root{
        height: 6vh;
        width: 23vw;
        font-size: 0.9vw;
    }

    .MuiTablePagination-selectLabel{
        font-family: 'appRegular' !important;
        font-size: 1vw;
    }
    
    .MuiTablePagination-displayedRows{
        font-family: 'appRegular' !important;
        font-size: 1vw;
    }
}

.trader-list-table {
    box-shadow: none !important;
    background-color: #FAFAFA !important;

    .MuiTableCell-footer {
        border: none;
        border-top: 0.5px solid #CCD6D9;
    }

    .MuiTablePagination-select{
        text-align: center !important;
    }
}

.trader-list-table-body{
    height: 100%;
    
    td{
        padding: 4px 10px;
        // font-family: 'appRegular';
        font-size: 1vw;
        font-weight: normal;
    }

    .MuiToolbar-root{
        min-height: 2vw !important;
        height: 2vw;
        overflow: hidden;
    }
}

@media screen and (min-width: 1922px) {
    .trader-list{
        height: 75vh;
    }
    .trader-list-table {
        height: 62vh;
    }
    .export-btn{
        width: 6vw !important;
    }
}

@media screen and (max-width: 1920px) {
    .trader-list{
        height: 75vh;
    }
    .trader-list-table {
        height: 61vh;
    }
}

@media screen and (max-width: 1707px) {
    .trader-list{
        height: 75vh;
    }
    .trader-list-table {
        height: 61vh;
    }
}

@media screen and (max-width: 1600px) {
    .trader-list{
        height: 75vh;
    }
    .trader-list-table {
        height: 61vh;
    }
    .export-btn{
        padding-bottom: 6px;
    }
}

@media screen and (max-width: 1423px) {
    .trader-list{
        height: 75vh;
    }
    .trader-list-table {
        height: 61vh;
    }
    .export-btn{
        padding: 0px;
    }
}

@media screen and (max-width: 1280px) {
    .trader-list{
        height: 75vh;
    }
    .trader-list-table {
        height: 61vh;
    }
    .export-btn{
        padding-bottom: 5px;
    }
}

@media screen and (max-width: 1165px) {
    .trader-list{
        height: 75vh;
    }
    .trader-list-table {
        height: 60vh;
    }
}

@media screen and (max-width: 1024px) {
    .trader-list{
        height: 74vh;
    }
    .trader-list-table {
        height: 59vh;
    }
}

.export-btn{
    font-family: 'appBold';
    font-size: 1vw;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    padding: 5px 35px;
    background-color: white;
    border-color: $bayer-dark-blue;
    color: $bayer-dark-blue;
    position:relative;
    width: 8vw;
    height: 6vh;
    cursor:pointer;
}

.export-icn{
margin-left: 10px;
height: 2.2vh;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
    color: #89D329;
  }

.tooltip-wrap {
    position: relative;
  }
  .tooltip-wrap .tooltip-content {
    border: 1px solid $bayer-light-blue;
    display: none;
    position: absolute;
    bottom: 5%;
    left: 5%;
    right: 5%;
    background-color: #fff;
    padding: .5em;
    width: 16vw;
    max-height: 20vh;
    z-index: 100;
    font-family: 'appRegular';
    font-size: 2vh;
    line-height: 2.5vh;
    overflow-y: auto;
    cursor: context-menu;
  }
  .tooltip-wrap:hover .tooltip-content {
    display: block;
  }

.error-stack{
    width: 100%;
    align-items: center;

    .error-img{
        height: 12vw;
        padding: 1vw 0vw;
    }

    .error-main{
        color: #707070;
        font-size: 1.2vw;
        font-family: 'appBold';
    }

    .error-sub{
        color: #004422;
        font-size: 1vw;
        font-family: 'appBold';
    }
}

.filter-indicator{
    width: 0.5em !important;
    height: 0.5em !important;
    margin: -1.4vh 0vh 0vh -1.2vh;
    color: #D0021B;
}