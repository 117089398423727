@import '../../assets/scss/colorCode.scss';

.table__row:hover{
    cursor: pointer;
    background-color: #f4f4f4;

}

.table__row{
    background-color: #FAFAFA;
}

.case-list-container{
    padding: 0vh 4vw 4vh 8vw;
}

.main-header {
    color: black;
    font-family: 'appRegular';
    font-size: 1.5vw;
    text-underline-offset: 8px;
    text-align: left;
    font-weight: bold;
}

.case-list-table-header {
    background-color: $bayer-light-green;
    .heading{
        font-family: 'appBold';
        font-size: 1vw;
        background-color: $bayer-light-green ;
        padding: 0.8vw 0.5vw;
    }
}

.table-footer {
    text-align: left;
    font-family: 'appRegular';
    font-size: 1.2vw;
    font-weight: normal;
    .faded{
        color: $bayer-dark-grey;
    }
}

.btm-btns-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.case-active {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-light-green;
    color: $bayer-green;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.case-inactive {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-light-red;
    color: $bayer-red;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.case-pending {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 80px;
    padding: 0.3vw;
    width: 5vw;
    background-color: $bayer-sky-blue;
    color: $bayer-light-blue;

    p{
        margin: 0.4vw 0vw;
        font-size: 0.8vw;
    }
}

.deactivate-btn {
    color: #e92c41; 
    border: 0px;
    background-color: #FAFAFA;
    cursor:pointer;
}

.deactivate-btn-disabled {
    border: 0px;
    background-color: #FAFAFA;
}

.filter-tag{
    margin: 0em 1em;
    font-family: 'appBold';
    font-size: 1.2vw;
}
.case-list{
    background-color: #FAFAFA;
    margin-top: 2vw;
    border: 0.5px solid #CCD6D9;
    border-radius: 12px;

    .MuiOutlinedInput-root{
        height: 6vh;
        width: 23vw;
        font-size: 0.9vw;
    }

    .MuiTablePagination-selectLabel{
        font-family: 'appRegular' !important;
        font-size: 1vw;
    }
    
    .MuiTablePagination-displayedRows{
        font-family: 'appRegular' !important;
        font-size: 1vw;
    }
}

.case-list-table {
    box-shadow: none !important;
    background-color: #FAFAFA !important;

    .MuiTableCell-footer {
        border: none;
        border-top: 0.5px solid #CCD6D9;
    }

    .MuiTablePagination-select{
        text-align: center !important;
    }
}

.case-list-table-body{
    height: 100%;
    
    td{
        padding: 0.5vw;
        // font-family: 'appRegular';
        font-size: 1vw;
        font-weight: normal;
    }

    .MuiToolbar-root{
        min-height: 2vw !important;
        height: 2vw;
        overflow: hidden;
    }
}

@media screen and (min-width: 1922px) {
    .case-list{
        height: 75vh;
    }
    .case-list-table {
        height: 62vh;
    }
    .export-btn{
        width: 6vw !important;
    }
}

@media screen and (max-width: 1920px) {
    .case-list{
        height: 75vh;
    }
    .case-list-table {
        height: 61vh;
    }
}

@media screen and (max-width: 1707px) {
    .case-list{
        height: 75vh;
    }
    .case-list-table {
        height: 61vh;
    }
}

@media screen and (max-width: 1600px) {
    .case-list{
        height: 75vh;
    }
    .case-list-table {
        height: 61vh;
    }
    .export-btn{
        padding-bottom: 6px;
    }
}

@media screen and (max-width: 1423px) {
    .case-list{
        height: 75vh;
    }
    .case-list-table {
        height: 61vh;
    }
    .export-btn{
        padding: 0px;
    }
}

@media screen and (max-width: 1280px) {
    .case-list{
        height: 75vh;
    }
    .case-list-table {
        height: 61vh;
    }
    .export-btn{
        padding-bottom: 5px;
    }
}

@media screen and (max-width: 1165px) {
    .case-list{
        height: 75vh;
    }
    .case-list-table {
        height: 60vh;
    }
}

@media screen and (max-width: 1024px) {
    .case-list{
        height: 74vh;
    }
    .case-list-table {
        height: 59vh;
    }
}

.export-btn{
    font-family: 'appBold';
    font-size: 1vw;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    padding: 5px 35px;
    background-color: white;
    border: 2px solid #10384f;
    color: $bayer-dark-blue;
    position:relative;
    width: 8vw;
    height: 6vh;
    cursor:pointer;
}

.refresh-btn{
    font-family: 'appRegular';
    font-size: 0.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #0091DF;
    border-radius: 4px;
    padding: 2px;
    background-color: white;
    color: #0091DF;
    position:relative;
    width: 5vw;
    height: 4vh;
    cursor:pointer;
}
.work-notes-btn{
    font-family: 'appRegular';
    font-size: 0.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #0091DF;
    border-radius: 4px;
    padding: 2px;
    background-color: white;
    color: #0091DF;
    position:relative;
    width: 7vw;
    margin-right: 1vw;
    height: 4vh;
    cursor:pointer;
}

.work-notes-btn-disabled{
    font-family: 'appRegular';
    font-size: 0.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #707070;
    border-radius: 4px;
    padding: 2px;
    background-color: white;
    color: #707070;
    position:relative;
    width: 7vw;
    margin-right: 1vw;
    height: 4vh;
}

// .work-notes-btn-save{
//     margin-top: 80px !important;
//     background-color: $bayer-bright-green !important;
//     border-radius: 30px !important;
//     border-color: transparent !important;
//     width: 10vw;
//     padding: 3px !important;
//     color: white !important;
//     text-transform: capitalize !important;
// }
.work-notes-btn-save{
    // margin-top: 80px !important;
    background-color: #89d329 !important;
    border-radius: 30px !important;
    border-color: transparent !important;
    width: 10vw;
    padding: 3px !important;
    color: white !important;
    text-transform: capitalize !important;
    text-decoration: none;
    text-decoration-color: transparent;
    // background-color: #89D329;
    // // width: 50%;
    // border-radius: 35px;
    // padding: 12px;
    // color: #fff;
    // font-size: 20px;
    // font-weight: 600;
    // text-transform: capitalize;
    // border: none
}

.work-notes-btn-save-disabled{
    // margin-top: 80px !important;
    background-color: grey !important;
    border-radius: 30px !important;
    border-color: transparent;
    width: 10vw;
    padding: 3px;
    color: white !important;
    // height: 6vh;
    margin-left: -6vw;
    text-transform: capitalize !important;
    text-decoration: none;
    text-decoration-color: transparent;
}
.confirm-btn-worknotes{
    text-transform: capitalize !important;
}

.work-notes-text-field{
    text-decoration: none !important;
    text-decoration-color: transparent !important;
}

.work-notes-btn-save:hover{
    background-color: $bayer-green !important;
}
// .work-notes-btn-disc{
//     margin-top: 80px !important;
//     background-color: grey !important;
//     border-radius: 30px !important;
//     border-color: transparent !important;
//     width: 10vw;
//     padding: 3px !important;
//     color: white !important;
//     margin-right: 1vw !important;
//     text-transform: capitalize !important;
// }
.work-notes-btn-disc {
    // margin-top: 80px !important;
    background-color: white !important;
    border-radius: 30px !important;
    border-color: #89d329 !important;
    width: 10vw;
    border: 1px solid !important;
    height: 5vh;
    padding: 15px !important;
    color: #89d329 !important;
    margin-right: 1vw !important;
    text-transform: capitalize !important;
    text-decoration: none;
    text-decoration-color: transparent;
}

.work-notes-btn-disc:hover{
    background-color: #F7F7F7 !important;
}

// .work-notes-popup{
//     color: black;
//     font-family: 'appRegular';
//     font-size: 1.5vw;
//     text-underline-offset: 8px;
//     text-align: left;
//     font-weight: bold;
//     text-decoration: underline;
//     text-decoration-color: $bayer-underline-header;
// }

.work-notes-popup > .MuiDialog-container > .MuiPaper-root{
    width: 65vw;
}

.export-icn{
margin-left: 10px;
height: 2.2vh;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
    color: #89D329;
  }

.tooltip-wrap {
    position: relative;
  }
  .tooltip-wrap .tooltip-content {
    border: 1px solid $bayer-light-blue;
    display: none;
    position: absolute;
    bottom: 5%;
    left: 5%;
    right: 5%;
    background-color: #fff;
    padding: .5em;
    width: 16vw;
    max-height: 20vh;
    z-index: 100;
    // font-family: 'appRegular';
    font-size: 2vh;
    line-height: 2.5vh;
    overflow-y: auto;
    cursor: context-menu;
  }
  .tooltip-wrap:hover .tooltip-content {
    display: block;
  }

.error-stack{
    width: 100%;
    align-items: center;

    .error-img{
        height: 12vw;
        padding: 1vw 0vw;
    }

    .error-main{
        color: #707070;
        font-size: 1.2vw;
        font-family: 'appBold';
    }

    .error-sub{
        color: #004422;
        font-size: 1vw;
        font-family: 'appBold';
    }
}

.filter-indicator{
    width: 0.5em !important;
    height: 0.5em !important;
    margin: -1.4vh 0vh 0vh -1.2vh;
    color: #D0021B;
}

.snow-id{
    color: #0091DF;
    cursor: pointer;
}

.action{
    padding-left: 1vw !important;
}

.case-list-table-header .heading p{
    color: #707070;
    font-size: 0.8vw;
}