@import '../../assets/scss/colorCode.scss';

.header__title{
    // font-size: 18px;
    font-size: 1.2vw;
    margin-left: 15px;
    color: $bayer-grey;
    font-family: 'appBold';

}

.header__custom{
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
}

.header__content{
    display: flex;
    justify-content: space-between;
}

.header__button{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: -4vw;
}

.header__button__blue{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}

.btn__text{
    color: $bayer-grey-one;
    font-family: 'appRegular';
    font-size: 1vw;

}

.btn__text__blue{
    color: #0091df;
    font-family: 'appRegular';
    font-size: 1vw;
    cursor: pointer;
}

.login__btn{
    // height: 8%;
    // width: 20%;
    font-size: 18px !important;
    margin-left: 25px !important;
    border-radius: 30px;
    background-color: $bayer-grey;
    color: $bayer-dark-blue !important;
    border: 3px solid $bayer-dark-blue !important;
    border-radius: 25px !important;
    text-transform: none !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    // border-color: $bayer-dark-blue
}

.header__container{
    max-width: 90%;
    min-height: 7vh !important;
}

.header__btn_text{
    font-size: 1vw;
}

// @media only screen and (min-width: 980px) {
//     .header__container{
//         max-width: 90%;
//         min-height: 15vh !important;
//         height: 15vh !important;
//     }
    
//   }
.bayer__logo_header{
    height: 40px;
}

    @media(min-width: 600px) {
        .header__container .header__content{
            min-height: 8vh !important;
            height: 8vh !important;
    
        }
    }

  @media only screen and (max-width: 1280px) and (min-width: 1024px) {
    .header__container .header__content{
        min-height: 8vh !important;
        height: 8vh !important;

    }
    .bayer__logo_header{
        height: 33px;
    }
    .header__title {
        font-size: 1vw;

    }
    .login__btn {
        border: 2px solid #10384F !important;
    }
  }

  @media only screen and (max-width: 2560px) and (min-width: 1920px) {
    .header__container .header__content{
        min-height: 8vh !important;
        height: 8vh !important;

    }
    .registration__text__div::before {
        top: 8vh;
    }
  }
  @media only screen and (max-width: 1600px) and (min-width: 1422px) {
    .header__container .header__content{
        min-height: 8vh !important;
        height: 8vh !important;

    }
    .registration__text__div::before {
        top: 8vh;
    }
  }