@import '../../../../assets/scss/colorCode.scss';

.entire-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1vh 0;
    .dropdowns-container{
        display: flex;
        width: 75%;
        .dropdown{
            width: 90%
        }
        // .countries-disabled:hover{
        //     cursor: not-allowed;
        // }
    }
    .add-or-reset-buttons-container{
        display: flex;
        align-items: center;
        column-gap: 0.5vw;
        .new__req__btn {
            font-family: "appRegular";
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 40px;
            padding: 7px 20px;
            background-color: white;
            border: 2px solid $bayer-dark-blue;
            color: #10384f;
            position: relative;
            width: 10vw;
            height: 6vh;
            cursor: pointer;
            font-size: 0.9vw;
            column-gap: 4px
        }
        .reset-button{
            text-transform: none;
            font-family: appRegular;

            .reset-icon{
                margin-right: 7px;
                width: 10px;
            }
            .disable{
                color: $bayer-dark-grey;
            }
            .enable{
                color: $bayer-light-blue;
            }
        }
    }
    
}

#select-crop, #select-product, #select-event, #select-country{
    @media(min-width: 1201px) and (max-width: 1500px) {
        padding: 5.5px 14px !important;
    }
    @media(min-width: 1001px) and (max-width: 1200px) {
        padding: 4.5px 14px !important
    }
    @media(min-width: 801px) and (max-width: 1000px) {
        padding: 3.5px 14px !important
    }
    @media(max-width: 800px) {
        padding: 2.5px 14px !important
    }
}

.close-button-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 4px;
    .close-button{
        background-color: $bayer-bright-green;
        text-transform: capitalize;
        border-radius: 15px;
        padding: 3px 8px;
        font-family: appRegular;
        font-size: 14px;
    }
    .close-button:hover{
        background-color: $bayer-green
    }
}

