@import '../../assets/scss/colorCode.scss';

$skew-amount: -20deg;
.accordian__text{
  font-size: 1.3vw;
  font-family: 'appRegular';

}
.accordian__two__text{
  font-size: 0.7em !important;
  @media(min-width: 1300px) {
    font-size: 0.9em !important;
  };
  white-space: pre-line;
  margin-left: -2px;
  padding-right: 3px;
  font-family: 'appRegular';
} 

.accordian__heading{
  color: $bayer-medium-grey;
  font-family: 'appBold';
  font-size: 1.3vw;
  margin-top: 30px;
  margin-bottom: 10px;

}

.accordian__heading:hover{
  font-size: 1.1vw;
  cursor: pointer;
  transition:.2s
}

.registration__text__div{
  display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}
.accordian__heading__active{
  font-size: 22px !important;
}

.btn__close_faq{
  bottom: 30px;
    position: absolute;

    h4{
      font-size: 1vw;
    }
}

.faqtoggle__close{

}

  // .slanted {
  //   background: white;
  //   width: 50%;
  //   position: absolute;
  //   left: 50%;
  //   transform: translateX(-50%) skew($skew-amount);
  //   text-align: justify;
  //   overflow-x: hidden;
  //   overflow-y: auto;
  //   height: calc(100% - 2rem);
  //   padding: 1rem;
    
  // }

  .userguide__close__btn{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .user__guidence {
    margin-left: -2vw;
    p{
      font-size: 1vw;
    }
    .user-guidelines-container{
      margin-top: 10px;
      font-family: appBold;
      font-size: 0.9em;
      display: flex;
      color: $bayer-medium-grey;
      img{
        margin-right: 8px;
        width: 20px;
      }
      img:hover{
        cursor: pointer;
      }
    }
  }

  .userguide__close__btn{
    font-family: 'appMedium';
    img:hover{
      cursor: pointer;
    }
  }

  .userguide__close__btn:hover{
    cursor: pointer;
    
  }

  .last-div > p{
    font-size: 1vw !important;
  }

  .training-video-link{
    text-decoration: underline;
    color: #fff;
  }
  .training-video-link:hover{
    cursor: pointer
  }